
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import Pagination from "@/comp/Pagination/index.vue";
import { getBloggerSquare } from "@/api/request/bigdata";

//组件
@Component({
  name: "SquareQuery",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = false;

  //列表请求参数
  private listQuery: any = {
    //页面数据
    page: 1,
    row: 20,

    //零时数据
    time: [], //时间
    useridStr: "", //上级ID

    //请求数据
    userid: 0, //用户ID
    nickname: "", //用户昵称
    end_time: "", //结束时间
    start_time: "", //开始时间
  };

  //时间选择器参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }

    //其他数据
    this.listQuery.userid = Number(this.listQuery.useridStr) > 0 ? Number(this.listQuery.useridStr) : undefined;

    //获取数据
    const { data } = await getBloggerSquare(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }
}
